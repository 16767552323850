import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from '@verme/react-uikit';
import Fingerprint from '@fingerprintjs/fingerprintjs';
// import * as faceapi from 'face-api.js';
// import { loadFaceApiModels } from './utils/loadFaceApiModels';

const telegram = window.Telegram.WebApp;

export const TelegramMiniApp: React.FC = () => {
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFp, setLoadingFp] = useState<boolean>(false);
  const [fingerprint, setFingerprint] = useState<string>();
  // const webcamRef = useRef<HTMLVideoElement>(null);
  // const canvasRef = useRef<HTMLCanvasElement>(null);
  // const [url, setUrl] = useState<string>('');
  // const [streamStarted, setStreamStarted] = useState<boolean>(false);
  // const descriptorFromLS = localStorage.getItem('descriptor');
  // const descriptor = descriptorFromLS ? Float32Array.from(Object.values(JSON.parse(descriptorFromLS))) : [];

  const getFingerprint = () => {
    setLoadingFp(true);

    const fingerprintPromise = Fingerprint.load();
    fingerprintPromise.then((fp) => {
      fp.get()
        .then((value) => {
          setFingerprint(value.visitorId);
        })
        .finally(() => setLoadingFp(false));
    });
  };

  useEffect(() => {
    getFingerprint();
    // loadFaceApiModels();
  }, []);

  const handleLocation = () => {
    setLoading(true);

    if (fingerprint) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            setLoading(false);
            sendEncodedData(coords);
          },
          () => {
            setLoading(false);
            toast.current?.show({
              severity: 'error',
              summary: 'Error',
              detail: 'Разрешите доступ к геолокации, чтобы продолжить.',
            });
          },
        );
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Геолокация недоступна в текущем браузере.',
        });
        setLoading(false);
      }
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Необходим цифровой отпечаток устройства, чтобы продолжить.',
      });
      setLoading(false);
    }
  };

  const sendEncodedData = (coords: GeolocationCoordinates) => {
    const { latitude, longitude, altitude, accuracy, altitudeAccuracy: altitude_accuracy, heading, speed } = coords;
    telegram.sendData(
      btoa(
        JSON.stringify({
          device_fingerprint: fingerprint,
          location_coordinates: [
            {
              latitude,
              longitude,
              altitude,
              accuracy,
              altitude_accuracy,
              heading,
              speed,
            },
          ],
        }),
      ),
    );
  };

  // -----------------------------------------------------------------
  // TODO: uncomment when photo identification functionality is needed
  // -----------------------------------------------------------------

  // const startStream = () => {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: { facingMode: 'user' } })
  //     .then((stream) => {
  //       setStreamStarted(true);

  //       const video = webcamRef.current;
  //       video!.srcObject = stream;
  //       video?.play();
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  // const getCapture = () => {
  //   const canvas = canvasRef.current;
  //   const webcam = webcamRef.current;

  //   if (canvas && webcam) {
  //     canvas.width = webcam.videoWidth;
  //     canvas.height = webcam.videoHeight;
  //     canvas.getContext('2d')?.drawImage(webcam, 0, 0);
  //     setUrl(canvas.toDataURL('image/webp'));
  //   }
  // };

  // const retakeCapture = () => {
  //   setUrl('');
  //   startStream();
  // };

  // const handleCapture = () => {
  //   setLoading(true);

  //   const img = await faceapi.fetchImage(url);
  //   const fullFaceDescription = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();

  //   if (!fullFaceDescription) {
  //     toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Лицо не обнаружено. Попробуйте снова.' });
  //     return;
  //   }

  //   let baseDesc;
  //   const maxDescriptorDistance = 0.6;

  //   if ('geolocation' in navigator) {
  //     if (descriptor.length !== 0) {
  //       baseDesc = descriptor;

  //       const faceMatcher = new faceapi.FaceMatcher(baseDesc, maxDescriptorDistance);
  //       const result = faceMatcher.matchDescriptor(fullFaceDescription.descriptor);

  //       if (result.distance < maxDescriptorDistance) {
  //     setLoading(false);
  //     navigator.geolocation.getCurrentPosition(
  //       ({ coords }) => {
  //         sendEncodedData(coords);
  //       },
  //       () =>
  //         toast.current?.show({
  //           severity: 'error',
  //           summary: 'Error',
  //           detail: 'Разрешите доступ к геолокации, чтобы продолжить.',
  //         }),
  //     );
  //       } else {
  //         toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Идентификация не пройдена.' });
  //         setLoading(false);
  //       }
  //     } else {
  //       localStorage.setItem('descriptor', JSON.stringify(fullFaceDescription.descriptor));
  //       navigator.geolocation.getCurrentPosition(({ coords }) => {
  //         sendEncodedData(coords);
  //       });
  //     }
  //   } else {
  //     toast.current?.show({
  //       severity: 'error',
  //       summary: 'Error',
  //       detail: 'Геолокация недоступна в текущем браузере.',
  //     });
  //   }
  // };

  // useEffect(() => {
  //   startStream();
  // }, [webcamRef]);

  return (
    <div className="text-variant-primary leading-6 flex flex-col gap-20">
      <Toast ref={toast} />
      <h1 className="text-24 font-bold">Создание отметки</h1>

      <div className="flex flex-col gap-16">
        <span>
          Чтобы начать процесс создания отметки нажмите на кнопку <b>&quot;Отправить&quot;</b>.
        </span>
        <Button label="Отправить" loading={loading} onClick={handleLocation} />
        {!fingerprint && (
          <Button label="Получить цифровой отпечаток" loading={loadingFp} outlined onClick={getFingerprint} />
        )}
        {/* <Button label="Повторить" outlined onClick={retakeCapture} />
            <img src={url} alt="Screenshot" /> */}

        {/* <div className="flex flex-col gap-12">
              <span>
                Сделайте фото с помощью кнопки <b>&quot;Сделать фото&quot;</b>
              </span>
              <span>
                Если не удалось получить доступ к камере нажмите кнопку <b>&quot;Разрешить доступ к камере&quot;</b>.
                Это может занять время.
              </span>
            </div>
            {streamStarted ? (
              <Button label="Сделать фото" onClick={getCapture} />
            ) : (
              <Button label="Разрешить доступ к камере" text onClick={startStream} />
            )}
            <video ref={webcamRef} id="webcam" muted autoPlay playsInline />
            <canvas ref={canvasRef} /> */}
      </div>
    </div>
  );
};
