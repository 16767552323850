import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { PrimeReactProvider, ThemeProvider } from '@verme/react-uikit';
import { store } from 'store';
import App from 'App';

const telegram = window.Telegram.WebApp;
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PrimeReactProvider>
        <ThemeProvider theme={telegram.colorScheme}>
          <App />
        </ThemeProvider>
      </PrimeReactProvider>
    </Provider>
  </React.StrictMode>,
);
