import React from 'react';
import { Page } from 'components/Layout';
import { TelegramMiniApp } from 'components/TelegramMiniApp';

const MiniAppView: React.FC = () => (
  <Page>
    <TelegramMiniApp />
  </Page>
);

export default MiniAppView;
