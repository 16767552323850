import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyles, localeOptions, addLocale, locale } from '@verme/react-uikit';
import MiniAppView from 'views/MiniAppView';

import '@verme/react-uikit/dist/style/tailwind.css';
import 'primeicons/primeicons.css';

const App: React.FC = () => {
  addLocale('ru', localeOptions);
  locale('ru');

  return (
    <Router>
      <GlobalStyles />
      <MiniAppView />
    </Router>
  );
};

export default App;
